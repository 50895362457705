import { observable, action } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Allocation } from './Allocation';
import { Activity } from './Activity';
import { User } from './User';
import { DATETIME_MINI_FORMAT, TIME_FORMAT, formatMoney } from 'helpers';
import emailTemplates from '../email';
import moment from 'moment-timezone';
import { camelCase, template } from 'lodash';
import { TRACKING_TARGET_TRAILER, TRACKING_TARGET_ALLOCATION } from './TrackedEquipment'
import { StatusUpdateAttachmentStore } from './StatusUpdateAttachment';

// function formatTimeCarefully(obj, prop) {
//     if (obj && obj[prop]) {
//         return obj[prop].format(DATETIME_MINI_FORMAT);
//     }
//     return '??';
// }

function formatTimeCarefully(datetime, location) {
    if (datetime) {
        if (location) {
            return moment(datetime).tz(location.timezone).format(DATETIME_MINI_FORMAT);
        }

        return datetime.format(DATETIME_MINI_FORMAT);
    }

    return '??';
}

function formatTimeCarefullyActivity(activity, prop) {
    if (activity && activity[prop]) {
        let datetime = activity[prop];

        if (activity.location) {
            datetime = moment(datetime).tz(activity.location.timezone);
        }

        return datetime.format(DATETIME_MINI_FORMAT);
    }
    return '??';
}

function isEmpty(activity) {
    if (!activity) {
        return true;
    }

    return activity.isNew;
}

const parseTemplate = function(input, variables) {
    let result = `${input}`;

    // Bug in eslint ref: https://stackoverflow.com/questions/57833013/why-am-i-getting-a-no-unused-vars-warning-in-a-for-of-loop-and-how-do-i-fix
    // eslint-disable-next-line no-unused-vars
    for (const variable in variables) {
        const r = new RegExp('{{' + variable + '}}', 'g');
        result = result.replace(r, variables[variable]);
    }
    return result;
};

function formatDuration(duration) {
  return duration && duration.asMinutes()
    ? duration.format('h:mm[h]', { trim: false })
    : '-';
}
function formatDatetime(date) {
  return date ? date.format(DATETIME_MINI_FORMAT) : '-';
}

function htmlDecode(input){
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export const drivingDelayReasons = [
    'traffic jam',
    'police control',
    'tacho stop',
    'other',
];

export const WAITING_DELAY_REASON_OTHER = 'other';
export const WAITING_DELAY_REASON_WRONG_REFERENCE = 'wrong reference';
export const WAITING_DELAY_REASON_LINE = 'line';

// Used for clearing date time value when on of these options is choosen.
export const WAITING_DELAY_REASON_WITHOUT_DATETIME = [
    WAITING_DELAY_REASON_OTHER,
    WAITING_DELAY_REASON_WRONG_REFERENCE,
    WAITING_DELAY_REASON_LINE
];

export const waitingDelayReasons = [
    WAITING_DELAY_REASON_WRONG_REFERENCE,
    'company closed',
    'not ready',
    WAITING_DELAY_REASON_LINE,
    WAITING_DELAY_REASON_OTHER
];

export class StatusUpdate extends Model {
    static backendResourceName = 'status_update';

    @observable id = null;
    @observable subject = '';
    @observable recipients = [];
    @observable content = '';
    @observable delayReason = 'other';
    @observable delayReasonTime = null;
    @observable createdAt = null;

    relations() {
        return {
            allocation: Allocation,
            activity: Activity,
            sentByUser: User,
            attachments: StatusUpdateAttachmentStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            delayReasonTime: Casts.datetime,
        };
    }

    @action
    generate({ allocation, activity, nextActivity, nextNoStopActivity, nextKindActivity, user }) {
        const assignment = allocation.currentAssignment;
        const driver = assignment.driver1;
        const driver2 = assignment.driver2;
        const twoDrivers = !!driver2.name | false;
        const now = moment();

        if (isEmpty(nextActivity)) {
            nextActivity = null;
        }

        let dayStart = driver.dayStart;
        let dayEnd = driver.dayEnd;
        let remainingDayDrivingTime = driver.remainingDayDrivingTime(now);
        let reason = '';
        let remainingWeekDrivingTime = driver.remainingWeekDrivingTime(now);

        if (twoDrivers) {
            dayStart = (driver2.dayStart) ? moment.min(driver.dayStart, driver2.dayStart) : dayStart;
            dayEnd = dayStart ? dayStart.clone().add(21, 'hours') : null;
            remainingDayDrivingTime = driver.remainingDayDrivingTime(now) && driver2.remainingDayDrivingTime(now) ? moment.duration(driver.remainingDayDrivingTime(now)).add(driver2.remainingDayDrivingTime(now)) : null;
            remainingWeekDrivingTime = driver.remainingWeekDrivingTime(now) && driver2.remainingWeekDrivingTime(now) ? moment.duration(driver.remainingWeekDrivingTime(now)).add(driver2.remainingWeekDrivingTime(now)) : null;
        }
        switch (this.delayReason) {
            case 'company closed':
                if(this.delayReasonTime) {
                    reason = `Reason: Company is having a break or is closed, they will start again at ${formatTimeCarefully(this['delayReasonTime'])}.`;
                }else {
                    reason = 'Reason: Company is having a break or is closed.'
                }
                break;
            case 'not ready':
                if(this.delayReasonTime) {
                    reason = `Reason: Goods are not ready yet. Will start at ${formatTimeCarefully(this['delayReasonTime'])}.`;
                }else {
                    reason = 'Reason: Goods are not ready yet, Will update if we have any news.'
                }
                break;
            case 'wrong reference':
                reason = 'Reason: Wrong reference, could you please provide a new one?';
                break;
            case 'line':
                reason = 'Reason: Truck is waiting in line, trucks in front of him.';
                break;
            default:
                reason = '';
                break;
        }

        const { amount, startCountingDatetime } = allocation.calcWaitingRateAmount(activity, true);

        let slot = '';

        if (allocation.contract && allocation.contract.tariffs && allocation.contract.tariffs.length && activity) {
            let tariff = allocation.contract.tariffs.at(0);
            if(tariff.waitingRates.length){
                let waitingRate = tariff.waitingRates.at(0);

                if(waitingRate && ['waiting', 'driving', 'arrived'].includes(activity.status) && waitingRate.useOrderedArrivalDatetime===true){
                    if(waitingRate.useOrderedArrivalDatetime && activity.orderedArrivalDatetimeFrom){
                        slot = t('statusUpdate.slot', {
                            from: formatTimeCarefully(activity.orderedArrivalDatetimeFrom, activity.location),
                            to: formatTimeCarefully(activity.orderedArrivalDatetimeUntil, activity.location),
                            })
                    }
                }
            }
        }

        let weekendBreak = ''
        if (twoDrivers) {
            if ([driver.nextWeekendbreakLength, driver2.nextWeekendbreakLength].includes('long')) {
                weekendBreak = '45H'
            } else {
                weekendBreak = '24H'
            }
        } else if (!twoDrivers && driver.nextWeekendbreakLength === 'short') {
            weekendBreak = '24H'
        } else if (!twoDrivers && driver.nextWeekendbreakLength === 'long') {
            weekendBreak = '45H'
        }

        const options = {
            //customerId: allocation.customerCode,
            customerId: allocation.contract.customer.useCustomerIdForUpdate ? allocation.customerCode : activity.assignment.truck.licensePlate,
            currentLocation: activity.location.boekNotation(),
            truckCurrentLocation: !activity.assignment.truck.currentPosition.isNew ? activity.assignment.truck.currentPosition.closestAddress : t('codeDetails.noGpsSignal'),
            currentArrivalTime: formatTimeCarefullyActivity(
                activity,
                'actualArrivalDatetime'
            ),
            currentFinishedTime: formatTimeCarefullyActivity(
                activity,
                'finishedDatetime'
            ),
            currentWaitStartTime: formatTimeCarefullyActivity(
                activity,
                'waitStartDatetime'
            ),
            currentCommunicatedTime: formatTimeCarefullyActivity(
                activity,
                'communicatedArrivalDatetime'
            ),
            slot: slot,
            currentStartTime: formatTimeCarefullyActivity(activity, 'startDatetime'),
            trailerPlate: activity.trailer.licensePlate,
            nextTrailerPlate: activity.nextTrailer.id
                ? ': ' + activity.nextTrailer.licensePlate
                : '',
            // I cringed while writing this. Just so you know.
            trailerDropOrPickup: activity.nextTrailer.id
                ? 'pick up'
                : 'drop',
            trailerDroppedOrPickedup: activity.nextTrailer.id
                ? 'picked up'
                : 'dropped',
            nextLocation: nextActivity
                ? nextActivity.location.boekNotation()
                : '??',
            nextNoStopLocation: nextNoStopActivity
                ? nextNoStopActivity.location.boekNotation()
                : '??',
            nextNoStopActivityId: nextNoStopActivity
                ? nextNoStopActivity.id
                : '',
            nextKindActivityId: nextKindActivity
                ? nextKindActivity.id
                : '',
            nextArrivalTime: formatTimeCarefullyActivity(
                nextActivity,
                'communicatedArrivalDatetime'
            ),
            userFullName: user.fullName,
            userEmailSignature: user.emailSignature.replace(new RegExp('\\n', 'g'), '<br />'),
            trailerEquipment: activity.trailer.equipments
                ? activity.trailer.equipments.map(eq =>
                    `${eq.name} ${t('statusUpdate.equipmentTrailerLabel')} ${eq.quantity}`).join('\n')
                : '',
            truckEquipment: activity.assignment.truck.equipments
                ? activity.assignment.truck.equipments.map(eq =>
                    `${eq.name} ${t('statusUpdate.equipmentTruckLabel')} ${eq.quantity}`).join('\n')
                : '',
            remainingDayDrivingTime: formatDuration(remainingDayDrivingTime),
            dayEnd: formatDatetime(dayEnd),
            status: activity.status === 'planned' ? 'eta' : activity.status,
            reason: reason,
            timezone: activity.location.timezone,
            timezoneOffsetHours: activity.timezoneOffsetHours,
            timezoneOffsetHoursWithCest: activity.timezoneOffsetHours ? `Times are in CEST ${activity.timezoneOffsetHours}` : '',

            waitingRateApplies: amount
                ? t('statusUpdate.waitingRateApplies', {
                    datetime: formatTimeCarefully(startCountingDatetime, activity.location),
                    amount: formatMoney(amount),
                }) : '',
            weekendBreak: `${t('statusUpdate.weekendBreak')}${weekendBreak}`,
            remainingWeekDrivingTime: formatDuration(remainingWeekDrivingTime),
            nextActivityId: nextActivity ? `${nextActivity.id}` : '',
            driverDC: driver ? `${driver.dataExternalId}` : '',
        };


        // For now we masquerade planned as eta, because the messages are the same.
        const status = activity.status === 'planned' ? 'eta' : activity.status;
        const noNextActivity = (status === 'finished' && !nextActivity) ? '-nonext' : '';
        const nextBoekActivity = (status === 'finished' && nextActivity && nextActivity.kind === 'boek stop') ? '-nextboek' : '';
        const delayReason = ( status === 'waiting' || status ==='eta' ) ? `-${camelCase(this.delayReason)}` : '';
        let templateName = '';

        if(activity.kind === 'customer stop') {
            templateName = `${camelCase(activity.kind)}-${camelCase(activity.type)}-${status}${delayReason}`.replace(/\s/g, '-');
        } else if (activity.kind === 'boek stop' && activity.type === 'service') {
            templateName = `${camelCase(activity.kind)}-${camelCase(activity.type)}-${status}${delayReason}`.replace(/\s/g, '-');
		}
		else {
            templateName = `${activity.type}-${status}${delayReason}${noNextActivity}${nextBoekActivity}`.replace(/\s/g, '-');
        }

        // Backwards compat for new templating and old templating.
        //
        // - new: use contract settings to define templates
        // - old: use checked in files to define templates
        //
        // Currently only support a few contract templates:
        //
        // - contract.activityActivityLoadFinished
        // - contract.activityActivityUnloadFinished
        // - contract.activityActivityTrailerChangeFinished
        const data = this.generateTemplateData({ allocation, activity, nextActivity, nextNoStopActivity, nextKindActivity, user }, options);

        if (allocation.contract.hasActivityStatusUpdateTemplate(activity)) {
            const { subjectTemplate, contentTemplate } = allocation.contract.getActivityStatusUpdateTemplate(activity);

            // Nasty how this works... So in contract you have a wysiwyg editor
            // which stores escaped HTML -> <%= %> will be escaped as well.
            // Here we unescape, apply templating, escape again...
            // So here we totally trust admin users.
            this.subject = template(subjectTemplate)(data);
            this.content = template(htmlDecode(contentTemplate))(data);
        } else {
            this.fillFromTemplate(templateName, options);

            // Allow new way of templating inside old templates.
            this.subject = template(this.subject)(data);
            this.content = template(this.content)(data);
        }
    }

    generateTemplateData({ allocation, activity, nextActivity, nextNoStopActivity, nextKindActivity, user }, predefinedTemplateData) {
        return {
            allocation,
            activity,
            nextActivity,
            nextNoStopActivity,
            nextKindActivity,
            statusUpdate: this.toJS(),
            user,
            ...predefinedTemplateData,
            // Special helper to make email templating easier. Usage:
            // trackedEquipment('Straps').before
            trackedEquipment(name) {
                return activity.trackEquipmentCounts(allocation.contract.trackedEquipments.find(te => te.equipmentType.name === name), allocation);
            },
            trackedTrailerEquipment(name) {
                return activity.trackEquipmentCounts(allocation.contract.trackedEquipments.find(te => te.equipmentType.name === name && te.trackingTarget === TRACKING_TARGET_TRAILER), allocation);
            },
            trackedAllocationEquipment(name) {
                return activity.trackEquipmentCounts(allocation.contract.trackedEquipments.find(te => te.equipmentType.name === name && te.trackingTarget === TRACKING_TARGET_ALLOCATION), allocation);
            },
            // Special helper to make email templating easier. Usage:
            // delayReason({"company closed":"Company was closed", "not ready": "Not ready", "wrong reference": "Wrong reference was provided", "line":"sth about line", "other":"Other sth"})
            delayReason: (delayReasons) => {
                switch (this.delayReason) {
                    case 'company closed':
                        return `Reason: ${delayReasons[this.delayReason]}.` + (!!this.delayReasonTime ? ` They will start again at ${formatTimeCarefully(this['delayReasonTime'])}.` : '');
                    case 'not ready':
                        return `Reason: ${delayReasons[this.delayReason]}` + (!!this.delayReasonTime ? `, will start at ${formatTimeCarefully(this['delayReasonTime'])}.` : '. Will update if we have any news.');
                    case 'wrong reference':
                    case 'other':
                    case 'line':
                        return `Reason: ${delayReasons[this.delayReason]}.`;
                    default:
                        return 'Reason: ';
                }
            }
        };
    }

    fillFromTemplate(templateName, options) {
        // I removed fetch because it breaks unit test, so we are using normal js string!
        const templateUrl = emailTemplates[templateName];
        if (!templateUrl) {
          this.subject = '';
          this.content = '';
          return;
        }

        const [
          templateSubject,
          templateContent,
        ] = templateUrl.trim().split('\n\n\n\n');

        // {html-newline-hack}
        /* eslint-disable no-control-regex */
        this.subject = parseTemplate(templateSubject, options).replace(new RegExp('\r?\n', 'g'), '<br />');
        this.content = parseTemplate(templateContent, options).replace(new RegExp('\r?\n', 'g'), '<br />');
        /* eslint-enable no-control-regex */
    }

    getFormattedCreatedAt() {
        return this.createdAt.format('D/M') + ' ' + this.createdAt.format(TIME_FORMAT)
    }

    hasAttachments() {
        return this.attachments && this.attachments.length > 0;
    }
}


export class StatusUpdateStore extends Store {
    Model = StatusUpdate;
    static backendResourceName = 'status_update';
}
